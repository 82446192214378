import { useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getClientConfigurations } from "../../redux/slice/RegisterAppSlice";

const ProviderDirectory = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getClientConfigurations())
  }, []);

  return (
    <div className="page-layout api-layout">
      <div className="api-description">
        <h2>Product Overview</h2>
        <p className="description" id="desc">
          Provider-related resources include insurance plan information,
          locations, healthcare services, and networks required to deliver
          provider directory information. Provider directory information also
          includes pharmacies.
        </p>
        <h2>Product Description</h2>
        <p className="description">
          <Typography sx={{ mb: 2 }}>
            As part of the CMS Interoperability and Patient Access final rule
            (CMS-9115-F), payers must make provider information available. These
            APIs are required to retrieve the provider information directly.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            There are nine provider resources that conform to the HL7 FHIR
            standards. These resources provide information on providers and
            pharmacies within the specific payer's network. Note that four of
            these resources also appear in the Clinical section.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            More details on this API can be found in the Capability Statement
            API.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            APIs in this category follow the PDEX Payer Network Implementation
            Guide v1.0.0 found at
          </Typography>
          <a
            href="https://build.fhir.org/ig/HL7/davinci-pdex-plan-net/profiles.html"
            target="blank"
          >
            https://build.fhir.org/ig/HL7/davinci-pdex-plan-net/profiles.html
          </a>
        </p>
      </div>
    </div>
  );
};
export default ProviderDirectory;
