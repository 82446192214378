import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getClientConfigurations } from "../../redux/slice/RegisterAppSlice";

const Formulary = () => {
   const dispatch = useDispatch<AppDispatch>()
    useEffect(()=>{
      dispatch(getClientConfigurations())
    },[])
    
  return (
    <div className="page-layout api-layout">
      <div className="api-description">
        <h2>Product Overview</h2>
        <p className="description" id="desc">
          The two resources in this category can provide complete payer
          formulary information.
        </p>
        <h2>Product Description</h2>
        <p className="description">
          <Typography sx={{ mb: 2 }}>
            As part of the CMS Interoperability and Patient Access final rule
            (CMS-9115-F), payers must make formulary information available.
            These APIs are required to retrieve the formulary information
            directly or on behalf of a member.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            There are two formulary resources that conform to the HL7 FHIR
            standards. These resources provide information that together provide
            information on the formulary.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            If information specific to a member is desired, then prior to
            calling this, you must have the proper authentication tokens.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            More details on this API can be found in the Capability Statement
            API.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            APIs in this category follow the DaVinci Payer Data Exchange US Drug
            Formulary Implementation Guide v1.0.1 found at
          </Typography>
          <a
            href="https://build.fhir.org/ig/HL7/davinci-pdex-formulary/artifacts.html#3"
            target="blank"
          >
            https://build.fhir.org/ig/HL7/davinci-pdex-formulary/artifacts.html#3
          </a>
        </p>
      </div>
    </div>
  );
};
export default Formulary;
