import {
    createSlice,
    createAsyncThunk,
    isPending,
    isRejected,
  } from "@reduxjs/toolkit";

import { RegisterApplicationApi } from "../../service/api/registerApplicationApi";
  
  const initialState = {
    isLoading: false,
    error: null as string | null,
    configurations:null,
    registeredApplication:null,
    applicationData:null,
  };
  
  export const registerApplication = createAsyncThunk(
    "user/registerApplication",
    async (data:object) => {
      try {
        const response = await RegisterApplicationApi.registerNewApp(data);   
        return response;
      } catch (error) {
        return error;
      }
    }
  );

  
// Redux action
export const updateRegisterdApplication = createAsyncThunk(
  'user/updateApplication',
  async ({ appId, data }: { appId: number, data: any }, thunkAPI) => {
    try {
      const response = await RegisterApplicationApi.updateApplication(appId, data);
      return response; // This will be the fulfilled action payload
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getClientConfigurations = createAsyncThunk(
  "user/getClientConfigurations",
  async () => { // payerCode is optional here
    try {
      const response = await RegisterApplicationApi.getClientConfiguration();
      return response;
    } catch (error) {
      return error;
    }
  }
);


  export const getApplicationData = createAsyncThunk("user/getApplicationData",async(id:any)=>{
   try{
      const response = await RegisterApplicationApi.getApplicationData(id)
      return response
   }catch(error){
    return error
   }
  })

  export const getRegisteredApps = createAsyncThunk(
    'user/getRegisteredApps',
    async ({ userId, pageSize, pageNumber }: { userId: number; pageSize: number; pageNumber: number }, thunkAPI) => {
      try {
        const response = await RegisterApplicationApi.getRegisteredApps(userId, pageNumber, pageSize);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );
  

  export const sendEmail = createAsyncThunk(
    "user/sendEmail",
    async (data: object, { rejectWithValue }) => {
      try {
        const response = await RegisterApplicationApi.sendEmail(data);
        return response;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  )
  
  const RegisterAppSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      RESET_STATE: () => initialState,
    },
    extraReducers: (builder) => {
      builder
        .addCase(getClientConfigurations.fulfilled, (state, action) => {
          state.isLoading = false;
          state.configurations = action.payload;
        })
        .addCase(getRegisteredApps.fulfilled, (state, action) => {
          state.isLoading = false;
          state.registeredApplication = action.payload;
        })
        .addCase(getApplicationData.fulfilled, (state, action) => {
          state.isLoading = false;
          state.applicationData = action.payload;
        })
        .addMatcher(isPending, (state) => {
          state.isLoading = true;
          state.error = null;
        })
        .addMatcher(isRejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message ?? null;
        });
    },
  });
  //export const { setIsChecked } = userSlice.actions;
  export default RegisterAppSlice.reducer;
  