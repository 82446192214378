import { urls } from "../Urls";
import { Http } from "../Http";
import { EmailService } from "../EmailService";

export class RegisterApplicationApi{
    static registerNewApp(data:object){
        return Http.postWithJson(urls.registerApplication.registerApp,data)
    }
    static sendEmail(data: any) {
        return EmailService.post(urls.registerApplication.sendEmail,
          data,
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );
      }
  
      static getClientConfiguration(payerCode?: string) {
        const url = urls.registerApplication.getClientconfigurations(); // URL will be created based on payerCode presence
        return Http.get(url); // Call the API with the correct URL
      }

      static getEmailRecipent(clientID:number){
        const url = urls.registerApplication.getEmailRecipent(clientID)
        return Http.get(url)
      }
    static getRegisteredApps(userId: number, pageNumber: number, pageSize: number) {
      const url = urls.registerApplication.getRegisteredApps(userId, pageNumber, pageSize);
      return Http.get(url);
    }
    static getApplicationData(id:any){
        const url = urls.registerApplication.getApplicationsData(id)
        return Http.get(url)
      }
      static updateApplication(appId:any, data:object) {
        const url = urls.registerApplication.updateApplication(appId);
        return Http.put(url, data); // Ensure Http.put takes URL and data
      }
   }

