import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import "./TopHeader.scss";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LeftPanelData } from "../../_mockData/LeftPanelData";
import { LeftPanelDataProd } from "../../_mockData/LeftPanelDataProd";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { capitalizeFirstLetter } from "../../utils/Helper";
import productlogo from "../../images/logo-white.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { API_DESCRIPTION } from "../../_mockData/MockData";

interface AppBarComponentProps {
  handleDrawerToggle: () => void;
}

export const AppBarComponent: React.FC<AppBarComponentProps> = ({
  handleDrawerToggle,
}) => {
  const [isDashboard, setIsDashboard] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName") || ""
  );
  const [lastName, setLastName] = useState(
    localStorage.getItem("lastName") || ""
  );
  const { environment } = useParams<{ environment?: string }>();
  const location = useLocation();
  const isProduction = environment === "prod";
  const currentData = isProduction ? LeftPanelDataProd : LeftPanelData;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [, setMenuWidth] = useState<number | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (buttonRef.current) {
      setMenuWidth((buttonRef.current as HTMLButtonElement).offsetWidth);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    handleClose();
    window.location.href = "/home"; // Navigate to home page
  };
  const navigate = useNavigate();
  const handleGoBack = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate(-1);
  };

  //remove this code once the data structure has been changed
  let activePathInfoData: any = {};
  for (let index = 0; index < currentData.length; index++) {
    const element = currentData[index];
    const descPathId = API_DESCRIPTION.find(
      (a) => a.path === location.pathname
    )?.id;
    if (element.path === location.pathname) {
      if (!activePathInfoData["info"]) {
        activePathInfoData["info"] = "";
      }
      if (!activePathInfoData["topTitle"]) {
        activePathInfoData["topTitle"] = element.parent;
      }

      if (!activePathInfoData["activeEndpoint"]) {
        activePathInfoData["activeEndpoint"] = ``;
      }

      if (!activePathInfoData["activeFolderInfo"]) {
        activePathInfoData["activeFolderInfo"] = "";
      }
    } else if (
      element?.children.filter((a) => a.path === location.pathname)?.[0]
    ) {
      const eachInfo = element?.children.filter(
        (a) => a.path === location.pathname
      )?.[0];
      if (!eachInfo) {
        continue;
      }
      if (!activePathInfoData["info"]) {
        activePathInfoData["info"] = eachInfo;
      }
      if (!activePathInfoData["topTitle"]) {
        activePathInfoData["topTitle"] = `/${eachInfo?.subiteminfo}`;
      }

      if (!activePathInfoData["activeEndpoint"]) {
        activePathInfoData["activeEndpoint"] = `/ ${eachInfo?.primarynested}`;
      }

      if (!activePathInfoData["activeFolderInfo"]) {
        activePathInfoData["activeFolderInfo"] = element?.parent;
      }
    } else if (
      element?.children.filter((a) => a.secondpath === location.pathname)?.[0]
    ) {
      const eachInfo = element?.children.filter(
        (a) => a.secondpath === location.pathname
      )?.[0];
      if (!eachInfo) {
        continue;
      }
      if (!activePathInfoData["info"]) {
        activePathInfoData["info"] = eachInfo;
      }
      if (!activePathInfoData["topTitle"]) {
        activePathInfoData["topTitle"] = `/${eachInfo?.id}`;
      }

      if (!activePathInfoData["activeEndpoint"]) {
        activePathInfoData["activeEndpoint"] = `/ ${eachInfo?.primarynested}`;
      }

      if (!activePathInfoData["activeFolderInfo"]) {
        activePathInfoData["activeFolderInfo"] = element?.parent;
      }
    } else if (
      element?.children.filter((a) => a.subiteminfo === descPathId)?.[0]
    ) {
      const eachInfo = element?.children.filter(
        (a) => a.subiteminfo === descPathId
      )?.[0];

      if (!eachInfo) {
        continue;
      }
      if (!activePathInfoData["info"]) {
        activePathInfoData["info"] = eachInfo;
      }
      if (!activePathInfoData["topTitle"]) {
        activePathInfoData["topTitle"] = eachInfo?.primarynested;
      }

      if (!activePathInfoData["activeEndpoint"]) {
        activePathInfoData["activeEndpoint"] = ``;
      }
      if (!activePathInfoData["activeFolderInfo"]) {
        activePathInfoData["activeFolderInfo"] = element?.parent;
      }
    }
  }

  const activePathInfo = activePathInfoData["info"];
  const topTitle = activePathInfoData["topTitle"];
  const endpoint = activePathInfoData["activeEndpoint"];
  const activeFolderInfo = activePathInfoData["activeFolderInfo"];
  const firstNameInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  const userInitials = `${firstNameInitial}${lastNameInitial}`;

  const handleDrawerToggleClick = () => {
    handleDrawerToggle();
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setFirstName(localStorage.getItem("firstName") || "");
      setLastName(localStorage.getItem("lastName") || "");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  useEffect(() => {
    const isDashboardMenu = window.location.href
      .toLowerCase()
      .includes("dashboard");
    const isregisterNewApplication = window.location.href
      .toLowerCase()
      .includes("registernewapplication");
    const isregisterNewDetails = window.location.href
      .toLowerCase()
      .includes("details");
    const isEnable =
      isDashboardMenu || isregisterNewApplication || isregisterNewDetails;
    if (isDashboardMenu) setActiveMenu("Dashboard");
    else if (isregisterNewApplication)
      setActiveMenu("Register New Application");
    else if (isregisterNewDetails) setActiveMenu("Register New Details");
    else setActiveMenu("");

    setIsDashboard(isEnable);
  }, [location.pathname]);

  return (
    <>
      <AppBar position="fixed" className="top-header">
        <div className="header-content">
          <Link to={`/home`} className="product-logo">
            <img src={productlogo} alt="productLogo"></img>
            <Typography className="version-info">V 0.1</Typography>
          </Link>
          <Button
            aria-label="More"
            size="large"
            onClick={handleDrawerToggleClick}
            className="more-icon"
          >
            <ArrowRightIcon />
          </Button>
          {isDashboard ? (
            <div className="breadcrumbs">
              <Typography className="main-path">{activeMenu}</Typography>
              {location.pathname !== "/Dashboard" && (
                <Link to="#" className="back-info" onClick={handleGoBack}>
                  <i className="ac_icons">ac_arrow_left</i>
                  <Typography>Back</Typography>
                </Link>
              )}
            </div>
          ) : (
            <div className="breadcrumbs">
              <Typography className="main-path">{topTitle}</Typography>
              <Typography className="path-info">
                {`${activeFolderInfo} ${endpoint}`}
              </Typography>
            </div>
          )}
          {firstName ? (
            <>
              <Button
                className="user-btn"
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                ref={buttonRef}
              >
                <Box className="mobile-view">
                  <MenuIcon />
                </Box>
                <Box className="web-view">
                  <div className="list-info">
                    <div className="welcome-info">Welcome back</div>
                    <div className="user-info">
                      {capitalizeFirstLetter(firstName)}
                      {lastName && `, ${capitalizeFirstLetter(lastName)}`}
                    </div>
                  </div>
                  <div className="user-letter">{userInitials}</div>
                </Box>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                className="user-profile-dropdown"
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: 250,
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  },
                }}
              >
                <Box className="mobile-view">
                  <div className="menu-info">
                    <div className="welcome-info">Welcome back,</div>
                    <div className="user-info">
                      {capitalizeFirstLetter(firstName)}
                      {lastName && ` ${capitalizeFirstLetter(lastName)}`}
                    </div>
                  </div>
                </Box>
                <MenuItem onClick={handleLogout} className="logout-menu">
                  Logout
                  <i className="an_icons">ac_logout</i>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Button className="sign-in" href="/userLogin">
              <i className="an_icons">ac_lock_fill</i>
              Sign in
            </Button>
          )}
        </div>
      </AppBar>
    </>
  );
};
