import sandbox from "../images/sandbox-icon.png"
import production from "../images/production-icon.png";
export const environments = [
    {
      key: "1",
      value: "Sandbox",
      label: "Sandbox",
      imgSrc: sandbox,
      imgAlt: "Sandbox",
      imgClass: "sandbox-img",
    },
    {
      key: "2",
      value: "Production",
      label: "Production",
      imgSrc: production,
      imgAlt: "Production",
      imgClass: "production-img",
    },
  ];

  export const organizations = [
    {
      value: "Payer",
      key: 4,
      label: "Payer",
      imgSrc: sandbox,
      imgAlt: "Payer",
      imgClass: "sandbox-img",
    },
    {
      value: "Thirdparty",
      key: 5,
      label: "Third Party",
      imgSrc: production,
      imgAlt: "Thirdparty",
      imgClass: "production-img",
    },
    {
      value: "ProviderEHR",
      key: 6,
      label: "Provider EHR",
      imgSrc: production,
      imgAlt: "Provider",
      imgClass: "production-img",
    },
  ];

 export const formatPhoneNumber = (phoneNumber:any) => {
    // Remove all non-digit characters
    const cleanedValue = phoneNumber?.replace(/[^\d]/g, "");
  
    // Only proceed if the cleaned value has at least 10 digits
    if (cleanedValue?.length === 10) {
      // Format the phone number as (xxx) xxx-xxxx
      return `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
    }
  
    // Return the original value if it's not a valid phone number
    return phoneNumber;
  };

  export const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  
  