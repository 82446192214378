let emailCode

if(process.env.NODE_ENV==="production"){
  emailCode="VZcpsMaJrM_Szw3twqahniiStPZ627wR94B_nA5xsKsdAzFunmKzDQ=="
}else{
  emailCode="aLASz7Yfu6AZlTDl69rMO2Lqzy3j2pZYIMs4eM9GMO8EAzFuRkH_ZQ=="
}
export const urls = {
    user: {
      createUser: "/User",
      loginUser:"/Auth/login",
      getUserData:(payerID:any)=>`/User/${payerID}`,
      ForgotPassword:"/User/forgot-password",
      resetPassword:"/User/reset-password",
    },
    registerApplication:{
      registerApp:"/RegisteredApps",
      updateApplication:(appID:any)=>`/RegisteredApps/${appID}`,
      getClientconfigurations: () => {
        const baseUrl = "/api/ClientConfigurations";
        let payerCode = localStorage.getItem("payerCode"); // Check localStorage directly here
        if (payerCode && payerCode !== "null" && payerCode.trim() !== "") {
          return `${baseUrl}?payerCode=${payerCode}`; // If payerCode is available, append it as a query parameter
        }
        return baseUrl; // If no payerCode, return the base URL without the query parameter
      },
      getTemplateById: (id: string) => `/template/get/${id}`,
      getRegisteredApps: (userId: number, pageNumber: number, pageSize: number) => {
        let payerCode = localStorage.getItem("payerCode"); // Check localStorage directly here
        let baseUrl = `/RegisteredApps?ConnectUserID=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=createdAt&sortOrder=desc`;
    
        // Only append payerCode to the URL if it's not null and not an empty string
        if (payerCode && payerCode !== "null" && payerCode.trim() !== "") {
            baseUrl += `&payerCode=${payerCode}`;
        }
    
        return baseUrl;  // Return the URL, with or without payerCode
    },
      sendEmail:`/SendEmail?code=${emailCode}`,
      getEmailRecipent:(id:number)=>`/api/PayerAdmin/GetPayerAdminEmails?payerId=${id}`,
      sendOtp:``,
      uploadLogo:"/Upload",
      getApplicationsData:(appID:any)=>`/RegisteredApps/${appID}`
    } 
  };
  
  