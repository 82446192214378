import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import "./PatientAPIAccess.scss";
import { createTheme, ThemeProvider, Container } from "@mui/material";
import Link from "@mui/material/Link";
import logoWhite from "../../images/logo-white.svg";
import logoBlue from "../../images/logo-blue.png";

import healthSun from "../../images/health-sun-logo.svg";
import optimum from "../../images/optimum-logo.svg";
import freedomHealth from "../../images/freedom-logo.svg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Unstable_Grid2";
import { TextField, Button, Typography } from "@mui/material";
import { getCurrentYear } from "../../utils/Utils";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1620,
    },
  },
});

function createData(
  logo: string,
  payername: string,
  sandboxurl: string,
  payerid: string
) {
  return { logo, payername, sandboxurl, payerid };
}

const rows = [
  createData(
    healthSun,
    "Health Sun",
    "32818196de8e4578b46ad2d0869c8b0b",
    "8476cfb7452b4575be4d6609877439a8"
  ),
  createData(
    optimum,
    "Optimum Healthcare",
    "99240C0D3F544501812F97B9D1BCE67D",
    "F87CDF9999264C81A948DC2EEA63C17D"
  ),
  createData(
    freedomHealth,
    "Freedom Health Plan",
    "E06B7F1BBE7D4E93AFB75F2C9928CC8D",
    "07E34CBA1C2044EC84518E2F42CCFC4D"
  ),
];

const Home: React.FC = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organizationName: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form data:", formData);
  };

  return (
    <>
      <main className={showNavbar ? "overflow-hidden" : ""}>
        <header className={scroll ? "header-sticky" : ""}>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <div className="header-box">
                {scroll ? (
                  <Link href="/providerdirectory/location-api/location">
                    <img src={logoBlue} alt="AaNeel Connect" />
                  </Link>
                ) : (
                  <Link href="/providerdirectory/location-api/location">
                    <img src={logoWhite} alt="AaNeel Connect" />
                  </Link>
                )}
                <div className="menu-icon" onClick={handleShowNavbar}>
                  <MenuIcon />
                </div>
                <div
                  className={`overlay ${showNavbar && "overlay-active"}`}
                  onClick={handleShowNavbar}
                ></div>
                <div className={`menu nav-elements  ${showNavbar && "active"}`}>
                  <div className="menu-logo">
                    <Link href="/providerdirectory/location-api/location">
                      <img src={logoBlue} alt="AaNeel Connect" />
                    </Link>
                    <CloseIcon onClick={handleShowNavbar} />
                  </div>
                  <ul>
                    <li>
                      <Link href="/home" className="active">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link href="/providerdirectory/location-api/location">
                        Access APIs
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Container>
          </ThemeProvider>
        </header>
        <section className="banner-section" style={{ minHeight: "125px" }}>
          <div>
            <ThemeProvider theme={theme}>
              <Container maxWidth="xl"></Container>
            </ThemeProvider>
          </div>
        </section>
        <section className="about-section">
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <Grid container direction="row" alignItems="flex-end">
                <Grid lg={6} md={12} mdOffset={6}>
                  <div className="title-main"></div>
                  <div className="about-content">
                    <Box
                      sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography component="h1" variant="h4">
                        GET STARTED. CREATE YOUR ACCOUNT.
                      </Typography>
                      <small className="blue">
                        Please Submit Your API Request
                      </small>
                      <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                      >
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          name="firstName"
                          autoComplete="given-name"
                          autoFocus
                          value={formData.firstName}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          autoComplete="family-name"
                          value={formData.lastName}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="organizationName"
                          label="Organization Name"
                          name="organizationName"
                          autoComplete="organization"
                          value={formData.organizationName}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          id="confirmPassword"
                          value={formData.confirmPassword}
                          onChange={handleChange}
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Register
                        </Button>
                      </Box>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>
        </section>

        {/* <section className='getstart-section'>
          <ThemeProvider theme={theme}>
            <Container maxWidth='xl'>
              <div className='title-main'>
                <small className='purple'>IT’S FREE</small>
                <h2 className='title-xl'>Let’s Get Started</h2>
              </div>

              <div className='getstarted-box'>
                <Grid container>
                  <Grid
                    lg={3}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pr: { xl: 8, lg: 6 },
                    }}
                  >
                    <div className='get-start-content'>
                      <p>
                        As per regulations, Provider Directory APIs are provided
                        without authentication requirements. After confirming
                        connectivity with the sandbox, utilize the details below
                        to connect to our Production APIs:
                      </p>
                      <p>
                        The URL for our Provider Directory Production endpoints
                        follows this format, where APINAME is substituted with
                        the API name you intend to call
                      </p>
                    </div>
                  </Grid>
                  <Grid lg={9} xs={12}>
                    <div className='box-main'>
                      <Box component='section' className='box-purple'>
                        <div className='box-header'>
                          <div className='box-header-left'>
                            <h4 className='title-lg'>Provider Directory</h4>
                            <p>
                              Resources related to providers encompass details
                              about insurance plans, locations, healthcare
                              services, and networks essential for disseminating
                              provider directory information. This information
                              also encompasses details about pharmacies.
                            </p>
                          </div>
                          <span>
                            <img
                              src={providerDirectory}
                              alt='Provider Directory'
                            />
                          </span>
                        </div>
                        <div className='box-content'>
                          <Grid container spacing={4}>
                            <Grid lg={6} xs={12}>
                              <Link
                                className='box-link'
                                href='/providerdirectory/location-api/location'
                              >
                                <div className='box-link-left'>
                                  <span>
                                    <img src={sandBoxUrl} alt='Sandbox URL' />
                                  </span>
                                  <div>
                                    <h5 className='title-sm'>Sandbox URL</h5>
                                    <p>
                                      https://api-sandbox.aaneelconnect.com/cms/r4/providerdirectory
                                    </p>
                                  </div>
                                </div>
                                <div className='box-link-right'>
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                            <Grid lg={6} xs={12}>
                              <Link
                                className='box-link'
                                href='/providerdirectory/location-api/location/prod'
                              >
                                <div className='box-link-left'>
                                  <span>
                                    <img
                                      src={productionUrl}
                                      alt='Production URL'
                                    />
                                  </span>
                                  <div>
                                    <h5 className='title-sm'>Production URL</h5>
                                    <p>
                                      https://api.aaneelconnect.com/cms/r4/providerdirectory
                                    </p>
                                  </div>
                                </div>
                                <div className='box-link-right'>
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                      <Box component='section' className='box-blue'>
                        <div className='box-header'>
                          <div className='box-header-left'>
                            <h4 className='title-lg'>Formulary</h4>
                            <p>
                              The two resources within this category have the
                              capability to furnish comprehensive payer
                              formulary details. Utilizing the Who Am I endpoint
                              for coverage information enables the retrieval of
                              specific member plan formulary details.
                            </p>
                          </div>
                          <span>
                            <img src={formulary} alt='Formulary' />
                          </span>
                        </div>
                        <div className='box-content'>
                          <Grid container spacing={4}>
                            <Grid lg={6} xs={12}>
                              <Link
                                className='box-link'
                                href='/formulary/list-api/list'
                              >
                                <div className='box-link-left'>
                                  <span>
                                    <img src={sandBoxUrl} alt='Sandbox URL' />
                                  </span>
                                  <div>
                                    <h5 className='title-sm'>Sandbox URL</h5>
                                    <p>
                                      https://api-sandbox.aaneelconnect.com/cms/r4/formulary
                                    </p>
                                  </div>
                                </div>
                                <div className='box-link-right'>
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                            <Grid lg={6} xs={12}>
                              <Link
                                className='box-link'
                                href='/formulary/list-api/list/prod'
                              >
                                <div className='box-link-left'>
                                  <span>
                                    <img
                                      src={productionUrl}
                                      alt='Production URL'
                                    />
                                  </span>
                                  <div>
                                    <h5 className='title-sm'>Production URL</h5>
                                    <p>
                                      https://api.aaneelconnect.com/cms/r4/formulary
                                    </p>
                                  </div>
                                </div>
                                <div className='box-link-right'>
                                  <Link sx={{ border: "none !important" }}>
                                    <span>
                                      <>
                                        {" "}
                                        <CallMadeOutlinedIcon />
                                      </>
                                    </span>
                                  </Link>
                                </div>
                              </Link>
                            </Grid>
                          </Grid>
                        </div>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </ThemeProvider>
        </section> */}

        {/* <section className='payer-section'>
          <ThemeProvider theme={theme}>
            <Container maxWidth='xl'>
              <div className='payer-box'>
                <Grid container>
                  <Grid
                    lg={3}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      pr: { xl: 8, lg: 6 },
                    }}
                  >
                    <div className='payer-content'>
                      <h4 className='title-lg'>Payer ID’s</h4>
                      <p>
                        Crucially, our Provider Directory APIs necessitate the
                        inclusion of the CHC Payer ID parameter. In the example,
                        CHCPAYERID should be replaced with the payer’s ID you
                        wish to access (refer to the table for a complete list
                        of Sandbox and Production Payer IDs)
                      </p>
                    </div>
                  </Grid>
                  <Grid lg={9} xs={12}>
                    <div className='payer-view'>
                      <div className='payer-grid-view'>
                        <div className='payer-table'>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label='simple table'
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Payer</TableCell>
                                  <TableCell>Sandbox</TableCell>
                                  <TableCell>Payer ID (Production)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {rows.map((row) => (
                                  <TableRow
                                    key={row.payername}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell component='th' scope='row'>
                                      <div className='payer-column'>
                                        <span>
                                          <img
                                            src={row.logo}
                                            alt={row.payername}
                                          />
                                        </span>
                                        <label>{row.payername}</label>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <Link href='/providerdirectory/location-api/location'>
                                        {row.sandboxurl}
                                        <CallMadeOutlinedIcon />
                                      </Link>
                                    </TableCell>
                                    <TableCell>
                                      <Link href='/providerdirectory/location-api/location/prod'>
                                        {row.payerid}
                                        <CallMadeOutlinedIcon />
                                      </Link>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </ThemeProvider>
        </section> */}

        <footer>
          <ThemeProvider theme={theme}>
            <Container maxWidth="xl">
              <Grid container className="bt-1" sx={{ py: { xl: 7, xs: 4 } }}>
                <Grid
                  lg={3}
                  xs={12}
                  className="footer-left"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Link
                    href="#"
                    sx={{ alignSelf: "flex-start", mb: 2.5, display: "flex" }}
                  >
                    <img src={logoBlue} alt="AaNeel Connect" />
                  </Link>
                  <span>© {getCurrentYear()} AaNeel Infotech, LLC.</span>
                </Grid>
                <Grid
                  lg={9}
                  xs={12}
                  className="footer-right"
                  sx={{ mt: { xl: 0.5 } }}
                >
                  <div>
                    <Grid container>
                      <Grid
                        lg={3}
                        md={4}
                        xs={6}
                        sx={{
                          order: { lg: 1, md: 3, xs: 4 },
                          mt: { xs: 1, md: 0 },
                        }}
                      >
                        <ul>
                          <li>
                            <span>
                              <PhoneIcon /> Sales:{" "}
                              <Link
                                href="tel:+1 (813) 909-9555"
                                sx={{ ml: 0.5 }}
                              >
                                +1 (813) 909-9555
                              </Link>
                            </span>
                          </li>
                          <li>
                            <span>
                              <MailIcon />{" "}
                              <Link href="mailto:Sales@aaneel.com">
                                Sales@aaneel.com
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{ order: { lg: 2, md: 2, xs: 2 } }}
                      >
                        <address>
                          6650 Gunn Hwy, <br /> Tampa, FL 33625
                        </address>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{ order: { lg: 3, md: 1, xs: 1 } }}
                      >
                        <ul>
                          <li>
                            <Link href="/home">Home</Link>
                          </li>
                          <li>
                            <Link href="/providerdirectory/location-api/location">
                              AaNeel Connect API
                            </Link>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={2}
                        md={4}
                        xs={6}
                        sx={{
                          order: { lg: 4, md: 4, xs: 3 },
                          mt: { xs: 1, md: 0 },
                        }}
                      >
                        <ul>
                          <li>
                            <Link href="/providerdirectory/endpoint-api/endpoint">
                              Provider
                            </Link>
                          </li>
                          <li>
                            <Link href="/formulary/list-api/list">
                              Directory Formulary
                            </Link>
                          </li>
                        </ul>
                      </Grid>
                      <Grid
                        lg={3}
                        md={4}
                        xs={6}
                        sx={{
                          order: { xs: 5 },
                          display: "flex",
                          justifyContent: {
                            lg: "flex-end",
                            md: "flex-start",
                          },
                          alignItems: { lg: "flex-start", md: "flex-end" },
                          mt: { xs: 1, md: 0 },
                        }}
                        className="social-box"
                      >
                        <div className="social-icons">
                          <Link
                            className="linkedin"
                            href="https://www.linkedin.com/company/aaneel-usa/mycompany/"
                            target="_blank"
                          >
                            <LinkedInIcon />
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} className="copy-right">
                  <span>© {getCurrentYear()} AaNeel Infotech, LLC.</span>
                </Grid>
              </Grid>
            </Container>
          </ThemeProvider>
        </footer>
      </main>
    </>
  );
};

export default Home;
