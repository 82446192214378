import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Collapse, ListItemIcon, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { LeftPanelData } from "../../_mockData/LeftPanelData";
import { LeftPanelDataProd } from "../../_mockData/LeftPanelDataProd";
import { API_DESCRIPTION } from "../../_mockData/MockData";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getClientConfigurations } from "../../redux/slice/RegisterAppSlice";

interface NestedDrawerItem {
  subiteminfo: string;
  primarynested: string;
  openState: boolean;
  id: string;
  path: string;
  secondpath: string;
}

interface DrawerItem {
  parent: string;
  primary: string;
  openState: boolean;
  id: string;
  path: string;
  secondpath?: string;
  children?: NestedDrawerItem[];
}

export const DrawerContent = () => {
  const { environment } = useParams<{ environment?: string }>();
  // Check if the environment is production
  const isProduction = environment === "prod";
  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>(
    isProduction ? LeftPanelDataProd : LeftPanelData
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<any>({});
  const location = useLocation();
  const navigate = useNavigate();
   const dispatch = useDispatch<AppDispatch>()

    const GetClientConfigurations=()=>{
      const urlParams = new URLSearchParams(window.location.search);
      
      if (!localStorage.getItem("payerCode") ) {
        let payerCode:any = urlParams.get("payerCode");
        localStorage.setItem("payerCode", payerCode);
        let response = dispatch(getClientConfigurations())
        return response
      }
    
    
    }
   useEffect(()=>{
    GetClientConfigurations()
   },[])

  useEffect(() => {
    const drawerItemsList = drawerItems.map((item) => {
      let isOpen = false;
      if (
        item.path === location.pathname ||
        item.secondpath === location.pathname
      ) {
        isOpen = true;
      } else if (item.children) {
        item.children.forEach((child) => {
          const descPath = API_DESCRIPTION.find(
            (a) => a.id === child.subiteminfo
          )?.path;
          if (
            child.path === location.pathname ||
            child.secondpath === location.pathname ||
            descPath === location.pathname
          ) {
            isOpen = true;
            child.openState = true; // Set the openState of the child to true
          }
        });
      }
      return { ...item, openState: isOpen };
    });
    setDrawerItems(drawerItemsList);
  }, [location.pathname]);

  const toggleDrawerItem = (index: number) => {
    setDrawerItems((prevDrawerItems) =>
      prevDrawerItems.map((item, i) => {
        const isOpen = index === i ? !item.openState : false; // Toggle open state of clicked parent item

        // Close all other parent items and their child items
        const updatedItem = {
          ...item,
          openState: isOpen,
          children: item.children
            ? item.children.map((child) => ({
                ...child,
                openState: false,
              }))
            : undefined,
        };

        // Ensure selected child item is opened when its parent is clicked
        if (isOpen && item.children) {
          const selectedChildIndex = item.children.findIndex((child) =>
            isItemChildSelected(child)
          );
          if (selectedChildIndex !== -1 && updatedItem.children) {
            updatedItem.children[selectedChildIndex].openState = true;
          }
        }
        const navPath = drawerItems?.[index]?.path;

        if (navPath) {
          navigate(navPath);
        }
        return updatedItem;
      })
    );
  };

  const toggleNestedDrawerItem = (parentIndex: number, childIndex: number) => {
    setDrawerItems((prevDrawerItems) =>
      prevDrawerItems.map((item, index) => {
        if (index === parentIndex && item.children) {
          return {
            ...item,
            children: item.children.map((child, i) => ({
              ...child,
              openState: i === childIndex ? !child.openState : false, // Toggle open state of clicked child item
            })),
            openState: true, // Ensure the parent menu remains open when a child item is toggled
          };
        }
        return item;
      })
    );
    const navPathId =
      drawerItems?.[parentIndex]?.children?.[childIndex]?.subiteminfo;
    const navPath = API_DESCRIPTION.find((a) => a.id === navPathId)?.path;
    if (navPath) {
      navigate(navPath);
    }
  };

  const isItemChildSelected = (item: NestedDrawerItem): boolean => {
    const descPath = API_DESCRIPTION.find(
      (a) => a.id === item.subiteminfo
    )?.path;
    return (
      location.pathname === item.path ||
      location.pathname === item.secondpath ||
      descPath === location.pathname
    );
  };

  const isItemSelected = (item: DrawerItem): boolean => {
    return (
      location.pathname === item.path || location.pathname === item.secondpath
    );
  };

  useEffect(() => {}, [location.pathname]);
  const handleTooltip = () => {
    let data: any = {};
    const elements = document.getElementsByClassName("text-overflow");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      const isOverflowing =
        element?.children[0]?.scrollWidth > element?.children[0]?.clientWidth;
      const id = element.getAttribute("data-id");
      if (id) {
        data[id] = isOverflowing;
      }
    }
    if (Object.keys(data)?.length > drawerItems.length) {
      setIsTooltipVisible(data);
    }
  };

  useEffect(() => {
    handleTooltip();
  }, [drawerItems]);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <section>
      <List>
        <div className="parent-list-menu">
          <ListItemButton href={"/home"} className="parent-menu-list">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 4,
                ml: 1,
              }}
            >
              <i className="ac_icons">ac_home_line</i>
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton
            href={isLoggedIn ? "/Dashboard" : "/userLogin"}
            className={`parent-menu-list ${
              location.pathname === "/Dashboard" ? "Mui-selected" : ""
            }`}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 4,
                ml: 1,
              }}
            >
              <i className="ac_icons">ac_dashboard_line</i>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </div>
        {drawerItems.map((item, index) => (
          <div key={item.parent} className={`parent-list-menu`}>
            <ListItemButton
              key={index}
              onClick={() => toggleDrawerItem(index)} // Toggle collapse
              selected={
                isItemSelected(item) ||
                (item.children &&
                  item.children.some((child) => isItemChildSelected(child)))
              }
              className={`parent-menu-list ${
                (isItemSelected(item) && !item.openState) ||
                (item.children && isItemChildSelected(item.children[0]))
                  ? "active-list"
                  : ""
              }`}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 4,
                  ml: 1,
                }}
              >
                {item.parent === "Provider Directory" && (
                  <i className="ac_icons">ac_stethoscope</i>
                )}
                {item.parent === "Formulary" && (
                  <i className="ac_icons">ac_new_task</i>
                )}

                {item.parent === "Payer to Payer API" && (
                  <i className="ac_icons">ac_bill_ready</i>
                )}
                {item.parent === "Patient API Access" && (
                  <i className="ac_icons">ac_accessibility</i>
                )}
                {item.parent === "Capability Statement" && (
                  <i className="ac_icons">ac_meet</i>
                )}
                {item.parent === "Authorization" && (
                  <i className="ac_icons">ac_lock_line</i>
                )}
              </ListItemIcon>
              {String(isTooltipVisible?.[`tooltip${index}`]) === "true" ? (
                <Tooltip title={item.parent}>
                  <ListItemText
                    className="text-overflow"
                    data-id={`tooltip${index}`}
                    primary={item.parent}
                  ></ListItemText>
                </Tooltip>
              ) : (
                <ListItemText
                  className="text-overflow"
                  data-id={`tooltip${index}`}
                  primary={item.parent}
                ></ListItemText>
              )}
              <ListItemText />
              {item.children && item.children.length > 0 && (
                <KeyboardArrowDownOutlinedIcon
                  style={{
                    transform: item.openState
                      ? "rotate(-180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              )}
            </ListItemButton>
            {item.children && item.openState && (
              <Collapse in={item.openState} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children.map((childItem, childIndex) => (
                    <div
                      key={`${childItem.primarynested}-nested`}
                      className={
                        isItemChildSelected(childItem) ? "active-sub-list" : ""
                      }
                    >
                      <ListItemButton
                        onClick={() =>
                          toggleNestedDrawerItem(index, childIndex)
                        } // Toggle nested item collapse
                        selected={isItemChildSelected(childItem)}
                        key={`${index}${childIndex}`}
                        className={
                          childItem.openState
                            ? "active-list sub-first-item"
                            : "sub-first-item"
                        }
                      >
                        <ListItemIcon>
                          <Box className="dot"></Box>
                        </ListItemIcon>
                        {String(
                          isTooltipVisible?.[`tooltip${index}${childIndex}`]
                        ) === "true" ? (
                          <Tooltip title={childItem.primarynested}>
                            <ListItemText
                              data-id={`tooltip${index}${childIndex}`}
                              className="text-overflow"
                            >
                              {childItem.primarynested}
                            </ListItemText>
                          </Tooltip>
                        ) : (
                          <ListItemText
                            className="text-overflow"
                            data-id={`tooltip${index}${childIndex}`}
                          >
                            {childItem.primarynested}
                          </ListItemText>
                        )}
                        <KeyboardArrowDownOutlinedIcon
                          style={{
                            transform: childItem.openState
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      </ListItemButton>
                      <Collapse
                        in={childItem.openState}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {[childItem.path, childItem.secondpath]
                            .filter((each) => each)
                            .map((path, si) => (
                              <ListItemButton
                                key={`${index}${childIndex}${si}`}
                                component={Link}
                                to={path}
                                selected={location.pathname === path}
                                sx={{ pl: 4 }}
                                className="nested-list-menu"
                              >
                                <ListItemIcon>
                                  <Box className="dot"></Box>
                                </ListItemIcon>

                                {String(
                                  isTooltipVisible?.[
                                    `tooltip${index}${childIndex}${si}`
                                  ]
                                ) === "true" ? (
                                  <Tooltip
                                    title={
                                      si === 0
                                        ? childItem.subiteminfo
                                        : childItem.id
                                    }
                                  >
                                    <ListItemText
                                      data-id={`tooltip${index}${childIndex}${si}`}
                                      className="text-overflow"
                                      primary={
                                        si === 0
                                          ? childItem.subiteminfo
                                          : childItem.id
                                      }
                                      sx={{
                                        opacity: si === 0 ? 1 : undefined,
                                      }}
                                    ></ListItemText>
                                  </Tooltip>
                                ) : (
                                  <ListItemText
                                    data-id={`tooltip${index}${childIndex}${si}`}
                                    className="text-overflow"
                                    primary={
                                      si === 0
                                        ? childItem.subiteminfo
                                        : childItem.id
                                    }
                                    sx={{
                                      opacity: si === 0 ? 1 : undefined,
                                    }}
                                  ></ListItemText>
                                )}
                                <ListItemIcon>
                                  <div className="get-item">
                                    {childItem.primarynested === "Token" ||
                                    childItem.primarynested === "Refresh Token"
                                      ? "POST"
                                      : "GET"}
                                  </div>
                                </ListItemIcon>
                              </ListItemButton>
                            ))}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
    </section>
  );
};
