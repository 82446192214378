import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Link,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  Typography,
  IconButton,
  Alert,
} from "@mui/material";
import logoWhite from "../../images/logo-white.svg";
import "./resetPassword.scss";
import companyLogo from "../../images/aaneel-logo.svg";
import logoBlue from "../../images/logo-blue.png";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { decodeBase64URL } from "../../utils/Helper";
import { Formik } from "formik";
import { Validators } from "../../utils/Validators";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { resetPassword } from "../../redux/slice/Authslice";
import { getCurrentYear } from "../../utils/Utils";

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = React.useState(false);
  const [error, setError] = useState("");
  const [expiredLinkError, setExpiredLinkError] = useState(false); // Add this state
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [key, setKey] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickshowConfirmPassword = () =>
    setshowConfirmPassword((show) => !show);

  useEffect(() => {
    const codeParam = searchParams.get("code");
    if (codeParam) {
      const decodedURL: any = decodeBase64URL(codeParam);
      const decodedObject = JSON.parse(decodedURL);
      setEmail(decodedObject.Email || "");
      setKey(decodedObject.Key || "");
    } else {
      console.error("Code not found in the URL");
    }
  }, [searchParams]);

  const handleSubmit = (values: any) => {
    const data = {
      email: email,
      resetCode: key,
      newPassword: values.newPassword,
    };
    dispatch(resetPassword(data)).then((response: any) => {
      if (response.payload) {
        navigate("/userLogin");
      } else {
        setExpiredLinkError(true); // Set error flag if the link is expired
      }
    });
  };

  return (
    <>
      <Box className="reset">
        <Box className="product-logo">
          <img src={logoWhite} alt="Logo" />
          <Typography className="version-info">V 0.1</Typography>
        </Box>
        <section className="reset-box">
          <label className="back-info">
            <Link href="/userLogin">
              <i className="ac_icons">ac_arrow_left</i>
              <Typography>Back</Typography>
            </Link>
          </label>
          <div>
            <img src={logoBlue} alt="Logo" className="tab-logo" />
            <div className="header-section">
              <h1>Reset Password?</h1>
            </div>

            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={Validators.ResetPasswordValidator}
              onSubmit={(values) => handleSubmit(values)}
            >
              {(props) => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                } = props;

                return (
                  <Box className="custom-controllers">
                    {error && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                      </Alert>
                    )}
                    <FormControl
                      margin="normal"
                      fullWidth
                      variant="filled"
                      required
                    >
                      <InputLabel htmlFor="email">Email Address</InputLabel>
                      <FilledInput
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        disabled
                        endAdornment={
                          <InputAdornment position="end">
                            <i className="ac_icons">ac_alternate_email</i>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl variant="filled" fullWidth required>
                      <InputLabel>New Password</InputLabel>
                      <FilledInput
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        autoComplete="new-password"
                        onBlur={handleBlur}
                        value={values.newPassword}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.newPassword && errors.newPassword && (
                        <FormHelperText error>
                          {errors.newPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl variant="filled" fullWidth required>
                      <InputLabel>Confirm Password</InputLabel>
                      <FilledInput
                        id="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        autoComplete="new-password"
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickshowConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                        <FormHelperText error>
                          {errors.confirmPassword}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      type="submit"
                      fullWidth
                      className="reset-btn"
                      variant="contained"
                      onClick={() => {
                        if (isValid) {
                          handleSubmit();
                        }
                      }}
                      sx={{ mt: 3, mb: 3 }}
                    >
                      Reset Password
                    </Button>
                    {expiredLinkError && ( // Show the expired link error
                      <Alert
                        severity="error"
                        sx={{
                          borderRadius: 4,
                        }}
                      >
                        Reset password link has expired. Please request a new
                        one.
                      </Alert>
                    )}
                  </Box>
                );
              }}
            </Formik>
          </div>
          <div className="footer-info">
            <div className="content-center">
              <div className="copyright-txt">© {getCurrentYear()} AaNeel Infotech LLC</div>
              <div className="company-logo">
                <div className="pwd-txt">Powered by</div>
                <img src={companyLogo} alt="Logo" />
              </div>
            </div>
          </div>
        </section>
      </Box>
    </>
  );
};

export default ResetPassword;
