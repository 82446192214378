import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Button,
  Box,
  Link,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  Typography,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import logoWhite from "../../images/logo-white.svg";
import "./forgetPassword.scss";
import companyLogo from "../../images/aaneel-logo.svg";
import logoBlue from "../../images/logo-blue.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { forgotPassword } from "../../redux/slice/Authslice";
import { sendEmail } from "../../redux/slice/RegisterAppSlice";
import { generateDateAndTime } from "../../utils/Helper";
import Loader from "../ui-elements/Loader";
import { Validators } from "../../utils/Validators";
import { getCurrentYear } from "../../utils/Utils";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(false);
  const [shwoMessage, setShowMessage] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const userInfo = useSelector((state: any) => state?.user.userData);
  const [username, setUsername] = useState({ email: "" });
  const dateTime = generateDateAndTime();
  const [clientEmailData, setClientEmailData] = useState({
    emailImportance: "normal",
    recipients: {
      to: [
        {
          displayName: "Helo13456",
          emailAddress: "",
        },
      ],
    },
    emailContents: {
      subject: " AaNeel Connect: Reset Password Request",
      plainText: "",
      html: "",
    },
    emailSender: "DoNotReply@aaneel.com",
  });

  useEffect(() => {
    setClientEmailData((prevState) => ({
      ...prevState,
      recipients: {
        to: [
          {
            displayName: "Hello dinesh",
            emailAddress: username.email,
          },
        ],
      },
    }));
  }, [username.email]);

  useEffect(() => {
    if (authCode) {
      fetchHtmlTemplate(username.email, authCode);
    }
  }, [authCode, username.email]);

  const fetchHtmlTemplate = async (email: string, code: string) => {
    try {
      const response = await fetch("/ResetPasswordTemplate.html");
      if (response.ok) {
        let html = await response.text();

        html = html.replace(/\[Username\]/g, email);
        html = html.replace(/\[code\]/g, code);
        html = html.replace(/\[time\]/g, dateTime);

        setClientEmailData((prevState: any) => ({
          ...prevState,
          emailContents: {
            ...prevState.emailContents,
            html,
          },
        }));
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleSubmit = async (formData: any) => {
    const { email } = formData;
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response: any = await dispatch(forgotPassword({ email }));
      if (response.payload) {
        setAuthCode(response.payload);
      } else {
        setErrorMessage("Email address not exist.");
      }
    } catch (error) {
      setErrorMessage("Email address not exist.");
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmailToClient = async () => {
    const formData = new FormData();
    formData.append("emailImportance", clientEmailData.emailImportance);
    formData.append("recipients", JSON.stringify(clientEmailData.recipients));
    formData.append(
      "emailContents",
      JSON.stringify(clientEmailData.emailContents)
    );
    formData.append("emailSender", clientEmailData.emailSender);
    dispatch(sendEmail(formData))
      .then((response: any) => {
        if (!response.payload.errors) {
          setIsLoading(false);
          setShowMessage(true);
          // alert("Reset password link has been sent to your email")
          // navigate("/");
        }
      })
      .catch((error: any) => {
        setErrorMessage("Email not found");
      });
  };

  useEffect(() => {
    if (authCode && clientEmailData.emailContents.html) {
      sendEmailToClient();
    }
  }, [authCode, clientEmailData.emailContents.html]);

  return (
    <>
      <Box className="forgot">
        <Box className="product-logo">
          <img src={logoWhite} alt="Logo" />
          <Typography className="version-info">V 0.1</Typography>
        </Box>
        <section className="forgot-box">
          <label className="back-info">
            <Link href="/userLogin">
              <i className="ac_icons">ac_arrow_left</i>
              <Typography>Back</Typography>
            </Link>
          </label>
          <div>
            <img src={logoBlue} alt="Logo" className="tab-logo" />
            <div className="header-section">
              <h1>
                Forgot <br></br>Password?
              </h1>
              <Box className="signup">
                <div className="info"> Remember password?</div>
                <Link href="/userlogin">Sign in</Link>
              </Box>
            </div>
            <p className="sub-info">
              Enter your email address below <br></br> we'll send you a link to
              reset your password.
            </p>
            <Formik
              initialValues={username}
              validationSchema={Validators.forgotPassword}
              onSubmit={(values, { setSubmitting }) => {
                setUsername(values);
                handleSubmit(values);
                setSubmitting(false);
              }}
            >
              {(props) => (
                <Form
                  noValidate
                  onSubmit={props.handleSubmit}
                  className="custom-controllers"
                >
                  <FormControl
                    margin="normal"
                    fullWidth
                    variant="filled"
                    error={props.touched.email && Boolean(props.errors.email)}
                    required
                  >
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <FilledInput
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      autoFocus
                      onChange={(e) => {
                        props.handleChange(e);
                        setUsername({ email: e.target.value });
                      }}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      endAdornment={
                        <InputAdornment position="end">
                          <i className="ac_icons">ac_alternate_email</i>
                        </InputAdornment>
                      }
                    />
                    {props.touched.email && props.errors.email && (
                      <FormHelperText>{props.errors.email}</FormHelperText>
                    )}
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    className="reset-btn"
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                    disabled={props.isSubmitting}
                  >
                    {isLoading ? <Loader /> : "  Send Reset Link"}
                  </Button>
                  {errorMessage && (
                    <Alert
                      severity="error"
                      sx={{
                        borderRadius: 4,
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  )}
                  {shwoMessage && (
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                      sx={{
                        borderRadius: 4,
                      }}
                    >
                      Reset password link has been sent to your email.
                    </Alert>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <div className="footer-info">
            <div className="content-center">
              <div className="copyright-txt">© {getCurrentYear()} AaNeel Infotech LLC</div>
              <div className="company-logo">
                <div className="pwd-txt">Powered by</div>
                <img src={companyLogo} alt="Logo" />
              </div>
            </div>
          </div>
        </section>
      </Box>
    </>
  );
};

export default ForgotPassword;
